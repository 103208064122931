<template>
  <div class="profile-container">
    <div class="banner" :style ="banner">
      <div class="min-size">
        <div class="about-us">
          <div class="title">
            公司介绍
            <el-divider class="title-inside-direct" direction="vertical"></el-divider>
            ABOUT US
          </div>

          <el-divider class="title-direct"></el-divider>

          <div class="desc" v-html="content">
            <!-- <div>湖南银方信息技术有限公司成立于2019年7月，注册资本2000万元人民币，位于长沙高新开发区银河科技园内，由湖南新绿新集团与湖南惠银数据科技有限公司共同发起合资成立，是一家运用金融科技力量服务“三农”的高新技术企业。</div>
            <div>湖南银方公司以深耕金融产业的服务能力为依托，携手全球知名的互联网企业，利用先进的AI技术、大数据风控技术，取得了浙江网商银行股份有限公司一级服务商资质，以及钉钉定制服务商、湖南销售部署服务商、湖南数字区县钉钉特许经营服务商资质，并在湖南省内开展了普惠金融服务、钉钉数字乡村部署服务、供应链金融服务、民办教育资金监管平台建设服务。同时，湖南银方分别与长沙市国有资本投资运营集团有限公司、永州市开发建设投资有限公司合资组建了长沙国投银方征信有限公司、永州云谷银方信息科技有限责任公司，并共同建设、运营长沙市农村综合金融服务平台、永州市综合金融服务平台。</div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="culture" :style ="culture">
      <div class="min-size">
        <div class="left">
          <div class="title">
            企业文化
            <el-divider class="title-inside-direct" direction="vertical"></el-divider>
            CORPORATE CULTURE
          </div>
          <el-divider class="title-direct"></el-divider>
          <div class="content">
            <div class="row" style="justify-content:start"><span style="margin-left:20px">我们的使命：</span></div>
            <div class="row">培育乡村振兴新引擎， 激活乡村振兴新动能。</div>
            <div class="row" style="justify-content:start"><span style="margin-left:20px">我们的目标：</span></div>
            <div class="row">成为一流的金融科技服务商和运营商。</div>
            <div class="row" style="justify-content:start"><span style="margin-left:20px">我们的宗旨：</span></div>
            <div class="row">推动金融科技新运用，助力乡村振兴。</div>
          </div>
        </div>

        <div class="right">
          <div class="title">
            核心价值观
            <el-divider class="title-inside-direct" direction="vertical"></el-divider>
            CORE VALUES
          </div>
          <el-divider class="title-direct"></el-divider>
          <div class="content">
            <div class="row">
              <div class="column">诚信立业</div>
              <div class="column">创新致远</div>
              <div class="column">同心同行</div>
              <div class="column">共创共赢</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="development">
      <div class="min-size">
        <div class="block-title">
          <div class="block-title-bg">DEVELOPMENT</div>
          <div class="block-title">
            企业发展
            <el-divider class="title-direct" direction="vertical"></el-divider>
            <div class="title-right">稳健运营 砥砺前行</div>
          </div>
        </div>

        <div class="block-content">
          <transition :name="timelineFade" >
            <div v-if="currentTimeLineItem.title" class="timeline-content">
              <div class="text">
                <div class="time">
                  {{ currentTimeLineItem.timestamp }}
                </div>
                <div class="title">
                  <div class="point"></div>
                  {{ currentTimeLineItem.title }}
                </div>
                <div class="desc" v-html="currentTimeLineItem.desc">
                </div>
              </div>
              <div class="image">
                <img :src="currentTimeLineItem.image" alt="timeline-image">
              </div>
            </div>
          </transition>
          <div class="timeline-block">
            <div class="timeline-prev">
              <svg-icon class-name="timeline-prev-icon" :icon-class="timelinePrevIcon" @click="moveLeft" @mouseenter="changeSvg(1, 'hover')" @mouseleave="changeSvg(1, '')" />
              <div class="timeline-line"></div>
            </div>
            <div class="timeline">
              <div class="timeline-item" v-for="(item, index) in data" :key="index">
                <div class="timeline-time">{{ item.timestamp }}</div>
                <div class="timeline-node" :class="{active: index === activeIndex}" @click="changeIndex(index)" @mouseenter="changeDirect(index)"></div>
                <div class="timeline-line"></div>
              </div>
            </div>
            <div class="timeline-next">
              <svg-icon class-name="timeline-next-icon" :icon-class="timelineNextIcon" @click="moveRight" @mouseenter="changeSvg(2, 'hover')" @mouseleave="changeSvg(2, '')" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="honor">
      <div class="min-size">
        <div class="block-title">
          <div class="block-title-bg">HONOR</div>
          <div class="block-title">
            荣誉自制
            <el-divider class="title-direct" direction="vertical"></el-divider>
            <div class="title-right">精神准则</div>
          </div>
        </div>

        <div class="block-content">
          <el-carousel :autoplay="false" indicator-position="none" arrow="always" trigger="click" height="197px">
            <el-carousel-item v-for="(itemsPerPage, index) in honorList" :key="index">
              <div class="image-list">
                <div class="image-item" v-for="(item, itemIndex) in itemsPerPage" :key="itemIndex">
                  <img class="honor" src="@/assets/yf/honor.png" alt="honor">
                  <img class="honor-item" :src="item" alt="honor-image">
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div> -->

    <div class="team">
      <div class="min-size">
        <div class="block-title">
          <div class="block-title-bg">TEAM</div>
          <div class="block-title">
            团队风采
            <el-divider class="title-direct" direction="vertical"></el-divider>
            <div class="title-right">多一些了解，多一份信任</div>
          </div>
        </div>

        <div class="block-content">
          <div class="top">
            <div class="col left">
              <img class="team" src="@/assets/yf/team-top1.png" alt="team">
              <div class="desc">
                <div class="name">朱良兵</div>
                <div class="company">湖南银方信息技术有限公司</div>
              </div>
            </div>
          
          </div>
          <div class="bottom">
            <el-carousel :autoplay="false" indicator-position="none" arrow="always" trigger="click" height="500px">
              <el-carousel-item v-for="(item, index) in teamList" :key="index">
                <img class="team-item" style="width:100%;" :src="item" alt="team-image">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchNewsList,archivesList,archivesDetail } from '@/api/api'
export default {
  name: 'Profile',
  components: {
  },
  data () {
    return {
      banner: {
        backgroundImage: 'url(' + require('@/assets/yf/profile-banner-bg.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center center'
      },
      culture: {
        backgroundImage: 'url(' + require('@/assets/yf/company-culture-bg.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center center'
      },
      timelinePrevIcon: 'Arrow-Left-Circle',
      timelineNextIcon: 'Arrow-Right-Circle',
      timelineFade: 'timeline-fade',
      activeIndex: 0,
      direct: 'right',
      currentTimeLineItem: {},
      total: 0,
      index:0,
      arr1:[],
      content:'',
      data: [
        {
          timestamp: '2021年5月',
          title: '长沙市农村综合金融服务平台正式上线运营',
          desc: '2021年5月，长沙市农村综合金融服务平台正式上线运营；同月，公司取得了浙江网商银行股份有限公司一级服务商资质。',
          image: '/static/demo/timeline5.png'
        },
        {
          timestamp: '2020年12月',
          title: '公司成为阿里钉钉湖南地区唯一的数字区县核心代理商',
          desc: '2020年12月，公司成为阿里钉钉湖南地区唯一的数字区县核心代理商；同月，长沙市农村综合金融服务平台上线试运营。',
          image: '/static/demo/timeline4.png'
        },
        {
          timestamp: '2020年4月',
          title: '组建长沙国投银方征信有限公司',
          desc: '2020年4月，公司与长沙市国有资本投资运营集团有限公司（国有独资）合资组建了长沙国投银方征信有限公司。',
          image: '/static/demo/timeline3.png'
        },
        {
          timestamp: '2019年12月',
          title: '助力网商银行完成县域普惠金融签约工作并上线',
          desc: '2019年8月-12月，助力网商银行完成醴陵市、长沙市、怀化市、湘潭市、衡阳市、岳阳市共计45个县域普惠金融签约工作，并成功上线。',
          image: '/static/demo/timeline2.png'
        },
        {
          timestamp: '2019年8月',
          title: '取得浙江网商银行股份有限公司“智慧县域+普惠金融”项目的资源型服务商资格',
          desc: '2019年8月，公司取得了浙江网商银行股份有限公司“智慧县域+普惠金融”项目的资源型服务商资格，并与长沙市政府签约。',
          image: '/static/demo/timeline1.png'
        },
      ],
      honorList: [
        [
          '/static/demo/honor1.png',
          '/static/demo/honor1.png',
          '/static/demo/honor1.png',
          '/static/demo/honor1.png'
        ],
        [
          '/static/demo/honor1.png'
        ]
      ],
      teamList: [
        '/static/demo/team2.png',
        '/static/demo/team3.png'
      ]
    }
  },
  created () {
    // 渲染静态数据
    this.total = this.data.length
    this.currentTimeLineItem = this.data[this.activeIndex]
    this.getList()
  },
  mounted(){
    
  },
  methods: {
    getList() {
      fetchNewsList().then(response => {
        response.data.map((r)=>{
          if(r.name == '湖南银方-首页'){
            r.children.map((m)=>{
              if(m.name=='公司简介-公司介绍'){
                archivesList({currentPage:1,currentSize:99,id:m.id}).then(response => {
                  let arr = []
                  arr = response.data.rows
                  archivesDetail({id:arr[0].id}).then(response => {
                    this.title = response.data.title
                    this.content = response.data.content
                    this.pushTime = response.data.pushTime
                  })
                })
              }

              if(m.name=='公司简介-企业发展'){
                archivesList({currentPage:1,currentSize:99,id:m.id}).then(response => {
                  let arr = []
                  arr = response.data.rows


                  arr.map((z)=>{
                    archivesDetail({id:z.id}).then(response => {
                      this.index = this.index+1
                      this.arr1.push(response.data)
                      if(this.index == arr.length){
                        this.arr1.map((x)=>{
                            let a = x.pushTime
                            a = a.split('-')
                            a = a.slice(0,2)
                            x.timestamp = a[0]+'年'+a[1]+'月'
                            x.title = x.summary
                            x.desc = x.content
                            x.image = x.thumbnail
                          })

                          this.arr1 = this.arr1.sort((a,b)=>{
                            var x = a.pushTime
                            var y = b.pushTime
                            return ((x>y)?-1:((x<y)?1:0))
                          })

                          this.data = this.arr1
                      }
                    })
                  })
                })
              }
            })
          }
        })
      })
    },
    moveLeft () {
      if (this.activeIndex > 0) {
        this.currentTimeLineItem = {}
        this.activeIndex = this.activeIndex - 1
        // this.currentTimeLineItem = this.data[this.activeIndex]

        const that = this
        setTimeout(function () {
          that.currentTimeLineItem = that.data[that.activeIndex]
        }, 800)
      }
    },
    moveRight () {
      if (this.activeIndex < this.total - 1) {
        this.currentTimeLineItem = {}
        this.activeIndex = this.activeIndex + 1
        // this.currentTimeLineItem = this.data[this.activeIndex]

        const that = this
        setTimeout(function () {
          that.currentTimeLineItem = that.data[that.activeIndex]
        }, 800)
      }
    },
    changeDirect (index) {
      if (this.activeIndex > index) {
        this.timelineFade = 'timeline-fade-left'
      } else {
        this.timelineFade = 'timeline-fade'
      }
    },
    changeIndex (index) {
      this.currentTimeLineItem = {}
      this.activeIndex = index
      // this.currentTimeLineItem = this.data[this.activeIndex]

      const that = this
      setTimeout(function () {
        that.currentTimeLineItem = that.data[that.activeIndex]
      }, 800)
    },
    changeSvg (key, way) {
      const temp = way === 'hover' ? '-Active' : ''
      switch (key) {
        case 1:
          this.timelineFade = 'timeline-fade-left'
          this.timelinePrevIcon = 'Arrow-Left-Circle' + temp
          break
        case 2:
          this.timelineFade = 'timeline-fade'
          this.timelineNextIcon = 'Arrow-Right-Circle' + temp
          break
      }
    }
  }
}
</script>

<style lang="less">
.profile-container {
  .block-title {
    display: flex;
    height: 104px;
    padding-bottom: 50px;

    .block-title-bg {
      position: absolute;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      color: rgba(0,0,0,0.03);
      font-size: 75px;
      line-height: 104px;
      font-weight: 700;
    }

    .block-title {
      padding-left: 20px;
      display: flex;
      font-size: 30px;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      font-weight: 700;
      color: #000000;
      line-height: 40px;
      align-items: center;

      .title-direct {
        height: 30px;
        width: 3px;
        background-color: #000000;
      }

      .title-right {
        font-size: 24px;
        font-weight: 400;
        color: rgba(102,102,102,1);
      }
    }
  }

  .banner {
    width: 100%;
    height: 940px;
    display: flex;
    justify-content: center;

    .min-size {
      width: 1200px;

      .about-us {
        padding-top: 308px;
        padding-left: 76px;

        .title {
          font-size: 24px;
          font-weight: 700;
          color: #ffffff;
          line-height: 40px;
          padding-bottom: 72px;

          .title-inside-direct {
            width: 3px;
            height: 30px;
          }
        }

        .title-direct {
          width: 90px;
          height: 3px;
        }

        .desc {
          text-indent:2em;
          padding-top: 36px;
          width: 534px;
          font-size: 14px;
          line-height: 26px;
          color: #ffffff;

        }
      }
    }
  }

  .culture {
    margin-top: 10px;
    height: 619px;
    display: flex;
    justify-content: center;

    .min-size {
      width: 1200px;
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 24px;
        font-weight: 700;
        color: #ffffff;
        line-height: 40px;
        display: flex;
        align-items: center;

        .title-inside-direct {
          width: 3px;
          height: 30px;
        }
      }

      .title-direct {
        width: 50px;
        height: 2px;
      }

      .content {
        padding-top: 43px;

        .row {
          font-size: 14px;
          color: #ffffff;
          line-height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:not(:first-child) {
            margin-top: 15px;
          }
        }
      }

      .left {
        padding-top: 100px;
        padding-left: 80px;

        .content {
          .row {
            background: rgba(255,255,255,0.10);
            border-radius: 80px;
            width: 376px;
            height: 50px;
          }
        }
      }

      .right {
        padding-top: 100px;
        padding-right: 80px;

        .content {
          width: 303px;
          .row {
            display: flex;
            flex-direction: column;
            .column {
              background: rgba(255,255,255,0.10);
              border-radius: 80px;
              width: 140px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;

              &:nth-child(n+2) {
                margin-top: 15px;
              }

              // &:nth-child(even) {
              //   margin-left: 23px;
              // }
            }
          }
        }
      }
    }
  }

  .development {
    height: 812px;
    display: flex;
    justify-content: center;
    align-items: center;

    .min-size {
      width: 1200px;

      .block-content {
        .timeline-fade-enter-active, .timeline-fade-left-enter-active {
          transition: all .3s ease;
        }

        .timeline-fade-leave-active, .timeline-fade-left-leave-active {
          transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
        }

        .timeline-fade-enter {
          transform: translateX(-100px);
          opacity: 0;
        }

        .timeline-fade-leave-to {
          transform: translateX(1000px);
          opacity: 0;
        }

        .timeline-fade-left-enter {
          transform: translateX(200px);
          opacity: 0;
        }

        .timeline-fade-left-leave-to {
          transform: translateX(-1000px);
          opacity: 0;
        }

        .timeline-content {
          padding-bottom: 40px;
          display: flex;
          justify-content: space-between;

          .text {
            .time {
              font-size: 24px;
              color: #000000;
              line-height: 40px;
            }

            .title {
              padding-top: 31px;
              display: flex;
              align-items: center;
              font-size: 14px;
              font-weight: 700;
              line-height: 40px;

              .point {
                width: 10px;
                height: 10px;
                background: #c00000;
                border-radius: 50%;
                margin-right: 8px;
              }
            }

            .desc {
              padding-left: 18px;
              width: 407px;
              font-size: 14px;
              color: #666666;
              line-height: 40px;
            }
          }

          .image {
            width: 656px;
            height: 400px;
            border: 10px solid #ffffff;
            box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 644px;
              height: 388px;
            }
          }
        }

        .timeline-block {
          display: flex;

          .timeline-prev {
            width: 145.75px;
            display: flex;
            align-items: center;

            .timeline-prev-icon {
              cursor: pointer;
              font-size: 34px;
            }

            .timeline-line {
              flex-grow: 1;
              border-top: 2px solid #E4E7ED;
            }
          }

          .timeline-next {
            .timeline-next-icon {
              cursor: pointer;
              font-size: 34px;
            }
          }

          .timeline {
            display: flex;
            align-items: center;
            .timeline-item {
              width: 145.75px;
              display: flex;
              align-items: center;
              position: relative;

              .timeline-node {
                width: 11px;
                height: 11px;
                background: #b2b2b2;
                border-radius: 50%;
                cursor: pointer;

                &.active {
                  background: #c00000;
                }
              }

              .timeline-line {
                flex-grow: 1;
                border-top: 2px solid #E4E7ED;
              }

              .timeline-time {
                font-size: 12px;
                color: #999999;
                line-height: 40px;
                position: absolute;
                top: -35px;
                left: -20px;
              }
            }
          }
        }
      }
    }
  }

  .honor {
    height: 430px;
    display: flex;
    justify-content: center;
    align-items: center;

    .min-size {
      width: 1200px;

      .block-content {
        .el-carousel__item {
          display: flex;
          justify-content: center;
        }

        .image-list {
          width: 1080px;
          height: 197px;
          display: flex;
          justify-content: space-between;

          .image-item {
            margin-top: 5px;
            width: 258px;
            height: 190px;
            background: #f5f5f5;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #A8AFB4;
            position: relative;

            img.honor {
              width: 34px;
              height: 34px;
              position: absolute;
              top: -6px;
              right: 25px;
            }

            img.honor-item {
              width: 234px;
              height: 166px;
            }
          }
        }
      }
    }
  }

  .team {
    height: 1063px;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;

    .min-size {
      width: 1200px;

      .block-content {
        width: 100%;
        height: 729px;
        position: relative;

        .top {
          position: absolute;
          top: 0;
          z-index: 3;
          width: 100%;
          height: 360px;
          display: flex;
          justify-content: center;

          .col {
            width: 270px;
            height: 360px;
            position: relative;

            &.right {
              margin-left: 10px;
            }

            img {
              width: 100%;
              height: 100%;
            }

            .desc {
              position: absolute;
              width: 100%;
              height: 79px;
              bottom: 0px;
              background: rgba(255,255,255,0.9);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .name {
                font-size: 18px;
                font-weight: 700;
                color: #333333;
                line-height: 30px;
              }

              .company {
                font-size: 14px;
                color: #666666;
                line-height: 30px;
              }
            }
          }
        }

        .bottom {
          padding-top: 281px;
          height: 448px;
        }
      }
    }
  }
}
</style>
